exports.components = {
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-exito-jsx": () => import("./../../../src/pages/exito.jsx" /* webpackChunkName: "component---src-pages-exito-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politicas-de-privacidad-js": () => import("./../../../src/pages/politicas-de-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidad-js" */),
  "component---src-pages-seguimiento-[id]-js": () => import("./../../../src/pages/seguimiento/[id].js" /* webpackChunkName: "component---src-pages-seguimiento-[id]-js" */),
  "component---src-pages-seguimiento-js": () => import("./../../../src/pages/seguimiento.js" /* webpackChunkName: "component---src-pages-seguimiento-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-pages-validate-comment-js": () => import("./../../../src/pages/validate-comment.js" /* webpackChunkName: "component---src-pages-validate-comment-js" */)
}

